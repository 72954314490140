import { lazy } from 'react';

export { ProfilePage } from './profile/profile.jsx';
export { TasksPage } from './tasks/tasks.jsx';
// export { Order as OrderPage } from './orders/order';
export { OrderV2 as OrderPage } from './orders/order_v2.jsx';
export { TestPage } from './test/test.jsx';
export { FormOrders as FormOrdersPage } from './form-orders/form-orders.jsx';
export { FormOrder as FormOrderPage } from './form-orders/form-order.jsx';
export { PriceOrder as PriceOrderPage } from './price-orders/price-order.jsx';
export { EPMainForm } from './ep-main/ep-main-OTK.jsx';
export { EPTachograph } from './ep-main/tachograph/ep-tachograph.jsx';
export { Diotrade } from './ep-main/diotrade/diotrade.jsx';
export { EpCO2Form } from './ep-main/CO2/ep-CO2.jsx';
export { EKMT } from './ep-main/EKMT/ekmt.jsx';
export { LabReport as LabReportPage } from './lab-reports/lab-report.jsx';
export { PolicyPage } from './insurance-policy/table/policy.jsx';
export { PolicyNew } from './insurance-policy/new-policy/policyNew.jsx';
export { WorkTime as WorkTimePage } from './worktime/worktime.jsx';
export { WorkTimeList as WorkTimeListPage } from './worktime/worktimeList.jsx';
export { EasyPayMessage } from './easypay/easypayMessage.jsx';
export { ShedulerPage } from '../components/scheduler/scheduler.jsx';
export { InsurancePriceList } from './insurance-policy/priceList/prices.jsx';

export * from './reports/index';

// typescript

export const ActsPage = lazy(() => import('./acts/acts'));
export const AdminkaPage = lazy(() => import('./adminka/adminka'));
export const AdminkaReportsPage = lazy(() => import('./adminka/reports/reports'));
export const CRMPage = lazy(()=> import ('./crm'))
export const EPPage = lazy(()=> import('./ep/ep'));
export const ManualMOC = lazy(()=> import('./manual/manuals-moc'));
export const ManualPage = lazy(()=> import('./manual/manual-page'));
export const OrdersForPay = lazy(() => import('./orderForPay/orders'));
export const OrderForPay = lazy(()=> import ('./orderForPay/OrderForPay'))
export const PriceOrdersPage = lazy(()=> import ('./price-orders/price-orders'))

export { HomePage } from './home/home.jsx';
export { ContractOffer } from './contract-offer/contract-offer';
export { Dashboard as DashboardPage } from './dashboard/dashboard';
export { EasyPayList } from './easypay/easypayList';
export { ImportCarsPage } from './import-cars';
export { LabReports as LabReportsPage } from './lab-reports/lab-reports';
export { News as NewsPage } from './news/news';
export { Orders as OrdersPage } from './orders/orders';
export { PolicyCard } from './insurance-policy/card-policy/policyCard';
export { TerminalListDocsPage } from './terminalListDocs/terminalListDocs';
