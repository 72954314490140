import { calculateStatus } from 'utils/calculate-order-status';
import { Document } from '../_documets';
import { gqlClient } from 'gql-client';
import { loader } from 'graphql.macro';
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import { normalizeCarNumber } from 'utils/normalize-car-number';

const getOrderQuery = loader('./queries/getOrderV2.gql');
const updateOrderQuery = loader('./queries/updateBuyersOrder.graphql');
const getPriceQuery = loader('./queries/getPrices.gql');
const individualValue = 'ФизЛицо';
const individualRef = 'c323438d-414d-11e8-80c6-0e0b1ac00045';

export const badActReasons = [ 'Платний', 'Одометр', 'РСЦ', 'Тендер' ];

export class Order extends Document {
  /**
    * Create a Document.
    * @param {string} ref ('new' as Deafault)
 */
  constructor(ref, { user, projects, lab, options }) {
    super({
      class_name: 'doc.buyers_order',
      ref: ref ?? 'new',
      date: dayjs(),
    });
    this.ClientPerson = '';
    this.badact_reason = undefined;
    this.ClientPersonPhone = '';
    this.contract = undefined;
    this.department = lab.ref;
    this.doc_amount = 0;
    this.ext_json = {};
    this.isSubContract = false;
    this.note = '';
    this.organization = lab?.org ?? {};
    this.paid = 0;
    this.partner = {
      ref: individualRef, name: individualValue,
    };
    this.pay_kind = '';
    this.price_type = lab.price_type;
    this.proj = projects.default.ref;
    this.protected = false;
    this.shipped = 0;
    this.useAddPrice = false;

    this.data = this._getEmptyDoc();
    this.initServices();
    this._projects = projects;
    this._isReadOnly = false;
    this._status = { text: '', status: '' };
    this._isClosed = false;

    this._price = lab.price;
    this._options = options;
    this._user = user;
    this._lab = lab;
    this._paymentData = undefined;
    this._canUseAddPrice = this._user.isAlterPrice ?? false;
  }

  get status () {
    return this._status;
  }

  get totalAmount() {
    return this.services?.reduce((acc, curr) => acc + curr.amount, 0) ?? 0;
  }

  get totalQty() {
    return this.services?.reduce((acc, curr) => acc + curr.quantity, 0) ?? 0;
  }

  _getEmptyDoc() {
    return {
      date: dayjs(),
      number_doc: '',
      partner: {
        ref: '', name: '', isCorporate: false,
        individual_legal: individualValue,
      },
    };
  }

  emptyServiceLine() {
    return { row: 1,
      nom: { ref: '', name: '' },
      quantity: 1,
      price: 0,
      nats: 0,
      spec: 0,
      discount_percent_automatic: 0,
      msto: false,
      amount: 0,
      vat_rate: '',
      vat_amount: 0,
    };
  }

  initServices() {
    if (!Array.isArray(this.services)) {
      this.services = [];
    }
    this.services.length = 0;
    this.services.push(this.emptyServiceLine());
  }

  renumberRows() {
    this.services.forEach((r, i) => {
      r.row = i + 1;
    });
  }

  recalcRowService(row, rowIndex) {
    const newRow = row ?? this.services.find((r) => r.row === rowIndex);
    this.calculateRowValues(newRow);

    if (isNaN(newRow.amount)) newRow.amount = 0;
    if (newRow.vat_rate === 'НДС20') {
      newRow.vat_amount = Math.round(newRow.amount / 6, -2);
    } else {
      newRow.vat_amount = 0;
    }
    return newRow;
  }

  recalcServices() {

    this.services = this.services.map((r) => this.recalcRowService(r));
  }

  calculateRowValues(row) {
    const qty = row.quantity || 1;
    const price = row.spec ? row.spec : row.price;
    const mstoVal = row.msto ? this._options.buyers_order.mstoValue : 0;
    const nats = (row.nats ?? 0) - mstoVal;
    const discontPerc = row.discount_percent_automatic ?? 0;
    const discontVal = price * discontPerc / 100;
    const amount = (price - discontVal + mstoVal + nats) * qty;
    row.amount = amount;
    return row;
  }

  getPrice(nomRef) {
    return Number(this._price.find((p) => p.nom === nomRef)?.price ?? 0);
  }

  getBasePrice(nomRef) {
    return Number(this._lab.price.find((p) => p.nom === nomRef)?.price ?? 0);
  }

  print() {
    console.log('Hi!!!');
  }

  export() {
    const docToSave = super.export();
    docToSave.partner = this.partner.ref;
    return docToSave;
  }


  onPriceLoaded (data) {
    this._price = data;
    this.services.forEach((r) => {
      const msto = r.msto ? (this._options.buyers_order.mstoValue) : 0;
      const newPrice = this.getPrice(r.nom.ref);
      const oldPrice = r.price;
      const priceDiff = newPrice - oldPrice + msto;
      r.price = newPrice;
      r.nats = r.nats - priceDiff + msto;
      this.calculateRowValues(r);
    });
    this.prepareServiceNatsSpec();
  };

  async changePriceType(price_type) {

    if (price_type !== this?.price_type &&
        price_type !== this?._lab.price_type) {
      return gqlClient
        .query({
          query: getPriceQuery,
          variables: { date: this.date, priceType: price_type } })
        .then(({ data }) => {
          this.onPriceLoaded(data.prices);
          return 1;
        });
    }

    return new Promise((resolve) => {
      if (price_type === this._lab.price_type) {
        this.onPriceLoaded(this._lab.price);
      }
      resolve(1);
    });
  }

  async save() {

    const docToSave = {
      ...this.data,
      ...this.export(),
      prices: undefined,
    };
    docToSave.ClientPerson = this.ClientPerson;
    docToSave.ClientPersonPhone = this.ClientPersonPhone;
    docToSave.contract = this.contract;
    docToSave.badact_reason = this.badact_reason;
    docToSave.department = this.department?.ref ?? this.department;
    const doc_amount = this.totalAmount;
    docToSave.doc_amount = doc_amount;
    docToSave.ext_json = { ...this.data?.ext_json, ...this.ext_json };
    docToSave.isSubContract = this.isSubContract;
    if (this.isSubContract) {
      const subNomsPrice = this.services.map((r) => ({
        nomRef: r.nom.ref,
        price: r.price,
      }));
      docToSave.ext_json = {
        ...docToSave.ext_json,
        subNomsPrice,
        subPartnerName: this.partner.name,
        subPartnerRf: this.partner.ref,
      };
      docToSave.partner_subcontract = this.partner.ref;
      docToSave.partner = this.contract.partner;
      docToSave.contract = this.contract.contract;
    } else {
      docToSave.partner_subcontract = undefined;
      docToSave.partner = this.partner.ref;
    }
    docToSave.note = this.note;
    docToSave.organization = this.organization?.ref;
    docToSave.paid = this.paid;
    docToSave.pay_kind = this.pay_kind;
    docToSave.price_type = this.price_type;
    docToSave.proj = this.proj;
    docToSave.protected = this.protected;
    docToSave.shipped = this.shipped;
    docToSave.useAddPrice = this.useAddPrice;

    docToSave.services = this.services.map((oldRow) => {
      const r = cloneDeep(oldRow);
      if (r.spec) delete r.spec;
      if (r.nats) delete r.nats;

      r.nom = r.nom.ref;
      r.price = r.amount / r.quantity;
      r.gos_code = normalizeCarNumber(r.gos_code ?? '')
      return r;
    });

    const response = await gqlClient.mutate({
      mutation: updateOrderQuery,
      variables: { input: docToSave },
    });

    if (response?.errors) {
      return Promise.reject(response.errors);
    }
    this.isNew = false;
    this.to1C = true;
    return Promise.resolve(this.ref);
  }

  prepareServiceNatsSpec() {
    this.services?.forEach((r) => {
      const msto = r.msto ? this._options.buyers_order.mstoValue : 0;
      r.price = this.getPrice(r.nom.ref);
      r.nats = 0;
      r.spec = 0;

      const discont = (r.discount_percent_automatic || 0) / 100;
      const discontValue = r.price * discont;
      const price = r.price - discontValue;
      const calcPrice = (r.amount / r.quantity) - msto;

      if (calcPrice >= price) {
        r.nats = calcPrice - price;
      } else {
        r.spec = calcPrice;
      }
      r.nats += msto;
    });
  }

  async loadRaw(ref) {
    return gqlClient
      .query({ query: getOrderQuery, variables: { ref: ref ?? this.ref } })
      .then(({data}) => {
        const loadOrder = data?.order?.[0] || {};
        loadOrder.paymentData = data?.payment?.[0]?.body;
        return loadOrder;
      });
  }

  updateDoc(data) {
    super.load(data);
    this._price = data?.ext_json?.subNomsPrice?.map(r => ({ ...r, nom: r.nomRef })) ?? data.prices;
    this._paymentData = data?.paymentData;
    this.department = data.department;
    this.doc_amount = data.doc_amount;
    this.contract = data?.contract;
    this.badact_reason = data?.badact_reason;
    this.ClientPerson = data.ClientPerson ?? '';
    this.ClientPersonPhone = data.ClientPersonPhone ?? '';
    this.isSubContract = data.isSubContract ?? undefined;
    this.subDocNumber = data.subDocNumber;
    this.ext_json = data.ext_json ?? {};
    this.note = data.note ?? '';
    this.organization = data.organization;
    this.partner = data.partner;
    this.pay_kind = data.pay_kind;
    this.price_type = data.price_type;
    this.proj = data.proj ?? this._projects?.default.ref;
    this.protected = data.protected;
    this.shipped = data.shipped ?? 0;
    this.useAddPrice = this.price_type === this._user.alter_price_type;
    this.data = data;
    this._status = calculateStatus(data);
    this.isReadOnly = this.protected || this.checkClosedPeriod();
    this.services = data.services;
    this.prepareServiceNatsSpec();
    this.recalcServices();
    if (this.ext_json?.phone) this.ClientPersonPhone = this.ext_json.phone;
  }

  async load(ref) {
    return this.loadRaw(ref)
      .then((data) => {
        if (!data?.ref) return undefined;
        this.updateDoc(data);
        return data;
      });
  }
}
